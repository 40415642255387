import React, { FC, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';

import MetaTags from '../components/MetaTags';
import Header from '../components/Header';
import Footer from '../components/Footer';

import { theme } from '../styles/theme';

import { TERMS_LINK, DMCA_LINK, PRIVACY_LINK } from '../constants/links';

import { handleScroll } from '../helpers/scrollTop';

const Layout = styled.section`
  display: flex;
  padding: 80px 10% 200px;
  min-height: 100vh;
  min-width: 100%;
  background-color: ${(props) => props.theme.colors.lightPeach2};
  align-items: center;
  justify-content: center;
  position: relative;

  @media ${(props) => props.theme.breakpoints.laptop} {
    padding: 150px 50px 250px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${(props) => props.theme.containerWidthForBreakpoints.laptop};
`;

const PageTitle = styled.h2`
  font-weight: 900;
  font-size: 22px;
  line-height: 1.45;
  text-transform: uppercase;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 36px;
  }
`;

const Heading = styled.h2`
  margin-top: 32px;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.45;
  text-transform: uppercase;

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 28px;
  }
`;

const HeadingList = styled.h4`
  margin-top: 32px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.45;
  text-transform: uppercase;

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 20px;
  }
`;

const Paragraph = styled.p`
  margin-top: 32px;
  font-size: 16px;
  line-height: 1.25;
  font-weight: 300;

  span {
    display: block;
  }

  ol {
    margin-left: 20px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 22px;
    text-align: justify;
  }
`;

const Link = styled.a`
  color: ${(props) => props.theme.colors.orange};
`;

const LinkedTitle = styled.a`
  display: block;
  text-decoration: underline;
`;

const Terms: FC = () => {
  useEffect(() => {
    handleScroll();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <MetaTags title='Terms and Conditions' />
      <Header />
      <Layout>
        <Container>
          <PageTitle>Terms and Conditions (&quot;Terms&quot;)</PageTitle>
          <Paragraph>Last updated: June 8th, 2021</Paragraph>
          <Paragraph>
            Please read these Terms and Conditions (&quot;Terms&quot;, &quot;Terms and
            Conditions&quot;) carefully before using the illumenotes.com website and/or the
            illumenotes.com mobile application (such mobile application, the “App” and the App
            and/or website together, or individually, the &quot;Service&quot;) operated by the
            Lovenotes Company (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;).
          </Paragraph>
          <Paragraph>
            <b>
              Please read these Terms carefully, as they (among other things) provide that you and
              us will arbitrate certain claims instead of going to court and that you will not bring
              class action claims against us
            </b>{' '}
            Please only create a Service account or otherwise use the Service if you agree to be
            legally bound by all terms and conditions herein. If you are viewing this on your mobile
            device, you can also view these Terms via a web browser at{' '}
            <Link href={TERMS_LINK}>https://illumenotes.com/terms</Link>. Your acceptance of these
            Terms creates a legally binding contract between you and the Company. If you do not
            agree with any aspect of these Terms, then do not create a Service account or otherwise
            use the Service.
          </Paragraph>
          <Paragraph>
            Note for Children. Use of the Service by anyone under the age of 18 is prohibited. By
            using the Service, you represent and warrant that you are at least 18 years of age.
          </Paragraph>
          <Paragraph>
            The Company’s Privacy Policy, at{' '}
            <Link href='https://www.iubenda.com/privacy-policy/7830743/full-legal' target='_blank'>
              https://www.iubenda.com/privacy-policy/7830743/full-legal
            </Link>{' '}
            (the “Privacy Policy”), describes the collection, use and disclosure of data and
            information (including location and usage data) by us in connection with the Service.
            The Privacy Policy, as may be updated by us from time to time in accordance with its
            terms, is hereby incorporated into these Terms, and you hereby agree to the collection,
            use and disclose practices set forth therein.
          </Paragraph>
          <Paragraph>
            The Service allows for users to upload content that may be viewed by other users. As
            further described below, inappropriate content, including without limitation content
            that is obscene, pornographic or defamatory, is not allowed and is subject to removal.
            If you see any prohibited content within the Service, please notify us. Users who upload
            prohibited content may have their accounts terminated.
          </Paragraph>
          <Heading>Communications</Heading>
          <Paragraph>
            By creating an Account on the Service, you automatically subscribe to newsletters,
            marketing or promotional materials and other information we may send. However, you may
            opt out of receiving any, or all, of these communications from us by following the
            unsubscribe link or instructions provided in any email we send.
          </Paragraph>
          <Heading>Content</Heading>
          <Paragraph>
            Our Service allows you to post, link, store, share and otherwise make available certain
            information, text or other material (&quot;Content&quot;) on the Service. You are
            responsible for the Content that you post on or through the Service, including its
            legality, reliability, and appropriateness. You acknowledge that all posted Media is
            stored on and made available through the Service by the Company’s servers and not on
            your device. We reserve the right at anytime to disclose the identity of the sender of a
            note if we determine in our sole discretion that it violates these Terms or our
            applicable policies. You understand that all Content is provided to you through the
            Service only on an “as-available” basis and we do not guarantee that the availability of
            Content will be uninterrupted or bug free. You agree you are responsible for all of your
            Content and all activities that occur under your user account.
          </Paragraph>
          <Paragraph>
            By posting Content on or through the Service, You represent and warrant that: (i) the
            Content is yours (you own it) and/or you have the right to use it and the right to grant
            us the rights and license as provided in these Terms, and (ii) that the posting of your
            Content on or through the Service does not violate the privacy rights, publicity rights,
            copyrights, contract rights or any other rights of any person or entity. We reserve the
            right to terminate the account of anyone found to be infringing on a copyright or
            violating any of the foregoing representations.
          </Paragraph>
          <Paragraph>
            In connection with your Content, you further agree that you will not: (i) use material
            that is subject to third party intellectual property or proprietary rights, including
            privacy and publicity rights, unless you are the owner of such rights or have permission
            from their rightful owner to post the material and to grant the Company all of the
            license rights granted herein; (ii) use material that is unlawful, defamatory, libelous,
            threatening, pornographic, obscene, harassing, hateful, racially or ethnically offensive
            or encourages conduct that would be considered a criminal offense, violate any law or is
            otherwise inappropriate; or (iii) post advertisements or marketing content or
            solicitations of business, or any content of a commercial nature. We may investigate an
            allegation that any Content does not conform to these Terms and may determine in good
            faith and in its sole discretion whether to remove such Content, which we reserve the
            right to do at any time. If you are a copyright holder and believe in good faith that
            your content has been made available through the Service without your authorization, you
            may follow the process outlined at <Link href={DMCA_LINK}>[DMCA Policy]</Link> to notify
            our designated agent (pursuant to 17 U.S.C. § 512(c)) and request that we remove such
            Content.
          </Paragraph>
          <Paragraph>
            You hereby acknowledge that you may be exposed to Content from other users that is
            inaccurate, offensive, obscene, indecent, or objectionable when using the Service, and
            further acknowledge that we do not control the Content posted by Service users and do
            not have any obligation to monitor (but do have the right) such Content for any purpose.
          </Paragraph>
          <Paragraph>
            You retain any and all of your rights to any Content you submit, post or display on or
            through the Service and you are responsible for protecting those rights. We take no
            responsibility and assume no liability for Content you or any third party posts on or
            through the Service. However, by posting Content publicly using the Service you grant us
            the worldwide, non-exclusive, fully paid-up, royalty-free, irrevocable, perpetual,
            sublicenseable and transferable right and license to use, modify, publicly perform,
            publicly display, reproduce, make derivative works of and distribute such Content on and
            through the Service and to additionally distribute and publicly perform your Content in
            connection with the Service and our (and our successor’s) business, in any media formats
            and through any media channels. You agree that this license includes the right for us to
            make your Content available to other users of the Service, who may also use your Content
            subject to these Terms.
          </Paragraph>
          <Paragraph>
            In addition, Content found on or through this Service are the property of illume
            company. or used with permission. You may not distribute, modify, transmit, reuse,
            download, repost, copy, or use said Content, whether in whole or in part, for commercial
            purposes or for personal gain, without express advance written permission from us.
          </Paragraph>
          <Heading>Accounts</Heading>
          <Paragraph>
            When you create an account with us, you guarantee that you are above the age of 18, and
            that the information you provide us is accurate, complete, and current at all times.
            Inaccurate, incomplete, or obsolete information may result in the immediate termination
            of your account on the Service.
          </Paragraph>
          <Paragraph>
            You are responsible for maintaining the confidentiality of your account and password,
            including but not limited to the restriction of access to your computer and/or account.
            You agree to accept responsibility for any and all activities or actions that occur
            under your account and/or password, whether your password is with our Service or a
            third-party service. You must notify us immediately upon becoming aware of any breach of
            security or unauthorized use of your account.
          </Paragraph>
          <Paragraph>
            You may not use as a username the name of another person or entity or that is not
            lawfully available for use, a name or trademark that is subject to any rights of another
            person or entity other than you, without appropriate authorization. You may not use as a
            username any name that is offensive, vulgar or obscene.
          </Paragraph>
          <Heading>MOBILE SERVICES; SMS</Heading>
          <Paragraph>
            Use of the App requires usage of data and messaging services provided by your wireless
            service carrier. In particular, the App may use SMS messaging to provide you the
            functionality of the Service. You hereby consent to receiving such messages. You
            acknowledge and agree that you are solely responsible for data usage fees, messaging
            fees and any other fees that your wireless service carrier may charge in connection with
            your use of the App.
          </Paragraph>
          <Heading>INTELLECTUAL PROPERTY</Heading>
          <Paragraph>
            As between you and us, we own all worldwide right, title and interest, including all
            intellectual property and other proprietary rights, in and to the Service and all usage
            and other data generated or collected in connection with the use thereof other than the
            Content (“Our Materials”). Except for as expressly set forth herein, you agree not to
            license, distribute, copy, modify, publicly perform or display, transmit, publish, edit,
            adapt, create derivative works from, or otherwise make any unauthorized use of Our
            Materials. You agree not to reverse engineer, decompile, disassemble or otherwise
            attempt to discover the source code, algorithm or programs underlying Our Materials. We
            reserve the right to modify or discontinue the Service or any version(s) thereof at any
            time in its sole discretion, with or without notice.
          </Paragraph>
          <Heading>REPRESENTATIONS AND WARRANTIES</Heading>
          <Paragraph>
            You represent, warrant and covenant that, in connection with these Terms or the Service,
            you will not and will not attempt to: (i) violate any laws, third party rights or our
            community guidelines and other policies; (ii) offer for sale or rent, sell or rent,
            purchase or otherwise transfer, deal in or dispose of illegal products or services or
            products or services that encourage illegal activities, controlled substances, offensive
            materials, stocks or other securities, pharmaceuticals, medical devices, firearms,
            weapons, explosives, hazardous materials, any item that has been subject to a recall or
            that you believe may be unsafe when used in an ordinary manner, alcohol, tobacco
            products, animals, plants or seeds; (iii) re-join or attempt to use the Service if we
            have banned or suspended you; (iv) defraud us or another user; or (vi) use another
            user’s account or allow another person to use your user account. Any illegal activities
            undertaken in connection with the Service may be referred to the authorities.
          </Paragraph>
          <Heading>LICENSE TO USE THE APP</Heading>
          <Paragraph>
            If you have downloaded the App, then, subject to your compliance with all the terms and
            conditions of these Terms, we grant you a limited, nonexclusive, nontransferable,
            revocable license to use the App on a compatible mobile device for your personal use, in
            each case in the manner enabled by us. If you are using the App on an Apple, Inc.
            (“Apple”) iOS device, the foregoing license is further limited to use permitted by the
            Usage Rules set forth in Apple’s App Store Terms of Service.
          </Paragraph>
          <Heading>LINKS TO OTHER WEB SITES</Heading>
          <Paragraph>
            Our Service may contain links to third party web sites or services that are not owned or
            controlled by us.
          </Paragraph>
          <Paragraph>
            We have no control over, and assume no responsibility for the content, privacy policies,
            or practices of any third party web sites or services. We do not warrant the offerings
            of any of these entities/individuals or their websites.
          </Paragraph>
          <Paragraph>
            You acknowledge and agree that we shall not be responsible or liable, directly or
            indirectly, for any damage or loss caused or alleged to be caused by or in connection
            with use of or reliance on any such content, goods or services available on or through
            any such third party web sites or services.
          </Paragraph>
          <Paragraph>
            We strongly advise you to read the terms and conditions and privacy policies of any
            third party web sites or services that you visit. Your use of such third party web sites
            or services are governed by such terms and conditions and privacy policies, not these
            Terms or our Privacy Policy.
          </Paragraph>
          <Heading>PROHIBITED USES</Heading>
          <Paragraph>
            As a condition of your use of the Service, you will not use the Service for any purpose
            that is unlawful or prohibited by these Terms. You may not use the Service in any manner
            that in our sole discretion could damage, disable, overburden, impair or interfere with
            any other party’s use of it. You may not obtain or attempt to obtain any materials or
            information through any means not intentionally made available through the Service. You
            agree not to scrape or otherwise use automated means to access or gather information
            from the Service, and agree not to bypass any robot exclusion measures we may put into
            place. In addition, you agree not to use false or misleading information in connection
            with your user account, and acknowledge that we reserve the right to disable any user
            account with a profile which we believe (in our sole discretion) is false or misleading
            (including a profile that impersonates a third party).
          </Paragraph>
          <Heading>ADDITIONAL TERMS</Heading>
          <Paragraph>
            When you use certain features or materials on the Service, or participate in a
            particular promotion, event or contest through the Service, such use or participation
            may be subject to additional terms and conditions posted on the Service. Such additional
            terms and conditions are hereby incorporated within these Terms, and you agree to comply
            with such additional terms and conditions with respect to such use or participation.
          </Paragraph>
          <Heading>TERMINATION</Heading>
          <Paragraph>
            We may terminate or suspend your account and bar access to the Service immediately,
            without prior notice or liability, under our sole discretion, for any reason whatsoever
            and without limitation, including but not limited to a breach of the Terms. Note that
            deleting the App from your device will not terminate your Service account. Sections
            Content, Fees, Intellectual Property, Representations and Warranties, Links to other Web
            Sites, Prohibited Uses, Termination, Apple, Indemnification, Limitation of Liability,
            Disclaimer, Arbitration, Governing Law and Miscellaneous will survive any termination of
            these Terms.
          </Paragraph>
          <Paragraph>
            If you wish to terminate your account, you may notify us and we will terminate your
            account and these Terms.
          </Paragraph>
          <Heading>APPLE</Heading>
          <Paragraph>
            You hereby acknowledge and agree that Apple, Inc.: (i) is not a party to these Terms;
            (ii) has no obligation whatsoever to furnish any maintenance or support services with
            respect to the App; (iii) is not responsible for addressing claims by you or any third
            party relating to the App, including any product liability claims, claims under consumer
            protection laws or claims under any other law, rule or regulation; (iv) has no
            responsibility to investigate, defend, settle or discharge any claim that the App or use
            thereof infringes any third party intellectual property rights; and (v) is a third party
            beneficiary of these Terms with the right to enforce its terms against you directly.
          </Paragraph>
          <Heading>INDEMNIFICATION</Heading>
          <Paragraph>
            You agree to indemnify and hold us and our affiliated companies, and each of their
            officers, directors and employees, harmless from any claims, losses, damages,
            liabilities, costs and expenses, including reasonable attorney’s fees, (any of the
            foregoing, a “Claim”) arising out of or relating to your use or misuse of the Service,
            breach of these Terms or infringement, misappropriation or violation of the intellectual
            property or other rights of any other person or entity, provided that the foregoing does
            not obligate you to the extent the Claim arises out of the Company’s willful misconduct
            or gross negligence. The Company reserves the right, at our own expense, to assume the
            exclusive defense and control of any matter for which you are required to indemnify us
            and you agree to cooperate with our defense of these claims.
          </Paragraph>
          <Heading>LIMITATION OF LIABILITY</Heading>
          <Paragraph>
            UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, SHALL WE OR OUR
            AFFILIATES, CONTRACTORS, EMPLOYEES, OFFICERS, DIRECTORS, AGENTS, OR THIRD PARTY
            PARTNERS, LICENSORS OR SERVICE PROVIDERS, BE LIABLE TO YOU FOR ANY SPECIAL, INDIRECT,
            INCIDENTAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES THAT ARISE OUT OF OR RELATE TO THE
            SERVICE, INCLUDING YOUR USE THEREOF, OR ANY OTHER INTERACTIONS WITH US, EVEN IF WE OR
            OUR AUTHORIZED REPRESENTATIVE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY OR INCIDENTAL OR
            CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU, IN
            WHICH CASE OUR LIABILITY WILL BE LIMITED TO THE EXTENT PERMITTED BY LAW. IN NO EVENT
            SHALL OUR TOTAL LIABILITY OR THAT OF OUR AFFILIATES, CONTRACTORS, EMPLOYEES, OFFICERS,
            DIRECTORS, AGENTS, OR THIRD PARTY PARTNERS, LICENSORS OR SERVICE PROVIDERS TO YOU FOR
            ALL DAMAGES, LOSSES, AND CAUSES OF ACTION ARISING OUT OF OR RELATING TO THESE TERMS OR
            YOUR USE OF THE SERVICE EXCEED ONE HUNDRED U.S. DOLLARS.
          </Paragraph>
          <Heading>DISCLAIMER</Heading>
          <Paragraph>
            THE SERVICE AND ANY CONTENT, INFORMATION OR OTHER MATERIALS MADE AVAILABLE IN
            CONJUNCTION WITH OR THROUGH THE SERVICE ARE PROVIDED “AS IS” AND WITHOUT WARRANTIES OF
            ANY KIND EITHER EXPRESS OR IMPLIED.
          </Paragraph>
          <Paragraph>
            TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, WE AND OUT LICENSORS,
            SERVICE PROVIDERS AND PARTNERS DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING,
            BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE, AND NON-INFRINGEMENT OF PROPRIETARY RIGHTS. WE AND OUR LICENSORS, SERVICE
            PROVIDERS AND PARTNERS DO NOT WARRANT THAT THE FEATURES AND FUNCTIONALITY OF THE SERVICE
            WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SERVICE
            OR THE SERVERS THAT MAKE AVAILABLE THE FEATURES AND FUNCTIONALITY THEREOF ARE FREE OF
            VIRUSES OR OTHER HARMFUL COMPONENTS. CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON
            IMPLIED WARRANTIES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE FOREGOING
            DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE
            ADDITIONAL RIGHTS.
          </Paragraph>
          <Heading>ARBITRATION</Heading>
          <Paragraph>
            This Section is referred to herein as the “Arbitration Agreement.” The parties agree
            that any and all controversies, claims, or disputes between you and us arising out of,
            relating to, or resulting from these Terms, shall be subject to binding arbitration
            pursuant to the terms and conditions of this Arbitration Agreement, and not any court
            action (other than a small claims court action to the extent the claim qualifies). The
            Federal Arbitration Act governs the interpretation and enforcement of this Arbitration
            Agreement.
          </Paragraph>
          <Paragraph>
            THIS PARAGRAPH IS REFERRED TO AS THE “CLASS ACTION WAIVER.” THE PARTIES AGREE THAT EACH
            PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT AS A
            PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE ACTION OR PROCEEDING.
            UNLESS BOTH PARTIES AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN MORE
            THAN ONE PERSON’S OR PARTY’S CLAIMS AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A
            CONSOLIDATED, REPRESENTATIVE, OR CLASS PROCEEDING. ALSO, THE ARBITRATOR MAY AWARD RELIEF
            (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ONLY IN FAVOR OF THE INDIVIDUAL
            PARTY SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF NECESSITATED BY
            THAT PARTY’S INDIVIDUAL CLAIM(S).
          </Paragraph>
          <Paragraph>
            Arbitration will be conducted by a neutral arbitrator in accordance with the American
            Arbitration Association’s (“AAA”) rules and procedures (the “AAA Rules”), as modified by
            this Arbitration Agreement. If there is any inconsistency between the AAA Rules and this
            Arbitration Agreement, the terms of this Arbitration Agreement will control unless the
            arbitrator determines that the application of the inconsistent Arbitration Agreement
            terms would not result in a fundamentally fair arbitration. The arbitrator must also
            follow the provisions of these Terms as a court would, including without limitation, the
            limitation of liability provisions above. You may visit{' '}
            <Link href='http://www.adr.org' target='_blank'>
              http://www.adr.org
            </Link>{' '}
            for information on the AAA and{' '}
            <Link href='http://www.adr.org/fileacase' target='_blank'>
              http://www.adr.org/fileacase
            </Link>{' '}
            for information on how to file a claim against us.
          </Paragraph>
          <Paragraph>
            The arbitration shall be held in the county in which you reside or at another mutually
            agreed location. If the value of the relief sought is $10,000 or less, you or us may
            elect to have the arbitration conducted by telephone or based solely on written
            submissions, which election shall be binding on each party, but subject to the
            arbitrator’s discretion to require an in-person hearing if the circumstances warrant.
            Attendance at any in-person hearing may be made by telephone by either or both parties
            unless the arbitrator requires otherwise.
          </Paragraph>
          <Paragraph>
            The arbitrator will decide the substance of all claims in accordance with the laws of
            the state of Delaware, without regard to its conflicts of laws rules, and will honor all
            claims of privilege recognized by law. The arbitrator shall not be bound by rulings in
            prior arbitrations involving different Service users, but is bound by rulings in prior
            arbitrations involving you to the extent required by applicable law.
          </Paragraph>
          <Paragraph>
            Payment of all filing, administration, and arbitrator fees (collectively, the
            “Arbitration Fees“) will be governed by the AAA’s Rules. Each party will be responsible
            for all other fees it incurs in connection with the arbitration, including without
            limitation, all attorney fees.
          </Paragraph>
          <Paragraph>
            All aspects of the arbitration proceeding, and any ruling, decision or award by the
            arbitrator, will be strictly confidential for the benefit of all parties.
          </Paragraph>
          <Paragraph>
            If a court decides that any term or provision of this Arbitration Agreement other than
            the Class Action Waiver is invalid or unenforceable, the parties agree to replace such
            term or provision with a term or provision that is valid and enforceable and that comes
            closest to expressing the intention of the invalid or unenforceable term or provision,
            and this Arbitration Agreement shall be enforceable as so modified. If a court decides
            that any of the provisions of the Class Action Waiver is invalid or unenforceable, then
            the entirety of this Arbitration Agreement shall be null and void. The remainder of
            these Terms will continue to apply.
          </Paragraph>
          <Heading>GOVERNING LAW</Heading>
          <Paragraph>
            These Terms shall be governed and construed in accordance with the laws of Delaware,
            United States, without regard to its conflict of law provisions.
          </Paragraph>
          <Paragraph>
            Our failure to enforce any right or provision of these Terms will not be considered a
            waiver of those rights. If any provision of these Terms is held to be invalid or
            unenforceable by a court, the remaining provisions of these Terms will remain in effect.
            These Terms constitute the entire agreement between us regarding our Service, and
            supersede and replace any prior agreements we might have had between us regarding the
            Service.
          </Paragraph>
          <Heading>CHANGES</Heading>
          <Paragraph>
            We may make modifications, deletions and/or additions to these Terms (“Changes”) at any
            time. Changes will be effective: (i) thirty (30) days after we provide notice of the
            Changes, whether such notice is provided through the Service user interface, is sent to
            the e-mail address associated with your account or otherwise; or (ii) when you opt-in or
            otherwise expressly agree to the Changes or a version of these Terms incorporating the
            Changes, whichever comes first.
          </Paragraph>
          <Heading>CONTACT US</Heading>
          <Paragraph>
            If you have any questions about these Terms, please contact us. Under these Terms, you
            consent to receive communications from us electronically.
          </Paragraph>
          <Heading>MISCELLANEOUS</Heading>
          <Paragraph>
            You agree that any action at law or in equity arising out of or relating to this
            Agreement or the Service that is not subject to arbitration shall be filed only in the
            state or federal courts in California (or a small claims court of competent
            jurisdiction) and you hereby consent and submit to the personal jurisdiction of such
            courts for the purposes of litigating any such action. The failure of any party at any
            time to require performance of any provision of these Terms shall in no manner affect
            such party’s right at a later time to enforce the same. A waiver of any breach of any
            provision of these Terms shall not be construed as a continuing waiver of other breaches
            of the same or other provisions of these Terms. If any provision of these Terms shall be
            unlawful, void, or for any reason unenforceable, then that provision shall be deemed
            severable from these Terms and shall not affect the validity and enforceability of any
            remaining provisions. These Terms, and any rights and licenses granted hereunder, may
            not be transferred or assigned by you, but may be assigned by us without restriction.
            This is the entire agreement between us relating to the subject matter herein and shall
            not be modified except in a writing, signed by both parties, or by a change to these
            Terms made by us as set forth herein.
          </Paragraph>
          <Heading>SELLER POLICY</Heading>
          <Paragraph>
            <span>
              Illume is a marketplace where you can sell your goods and experiences to customers
              around the world. We want to make sure that you and your customers have a positive
              experience on illume. Please read on to find out more about your rights, as well as
              what is expected of you, as a seller. This policy is a part of our Terms of Use. By
              opening an illume shop, you’re agreeing to this policy and our Terms of Use.
            </span>
            <br />
            <LinkedTitle href='#selling-basics'>1. Selling Basics</LinkedTitle>
            <span>
              a. What can be sold on illume <br />
              b. What can't be sold on illume <br />
              c. Managing your illume shop <br />
              d. Seller Standards
            </span>
            <br />
            <LinkedTitle href='#illume-member'>
              2. Being a Member of the illume Community
            </LinkedTitle>
            <span>
              a. Creating and Uploading Content <br />
              b. Privacy and Protecting Personal Information <br />
              c. Communication Standards <br />
              i. Communicating Cancellations
            </span>
            <br />
            <LinkedTitle href='#feedback'>3. Feedback, Disputes, and Your Success</LinkedTitle>
            <span>
              a. illume’s Case System <br />
              c. Your Seller Account and illume’s Terms
            </span>
          </Paragraph>
          <HeadingList id='selling-basics'>1. Selling Basics</HeadingList>
          <Paragraph>
            Keeping in mind these basic requirements will set you up for success on illume.
          </Paragraph>
          <Paragraph>
            <span>a. What can be sold on illume</span> <br />
            <span>
              illume is a unique marketplace. Customers come here to purchase items or experiences
              that they might not find anywhere else. Everything listed for sale on illume must be
              environmentally-friendly.
            </span>
            <br />
            <span>If you are selling personalized or made-to-order items, you agree that:</span>
            <ol>
              <li>All listings are available for purchase at a set price.</li>
              <li>
                If you are using photographs of previous work with options for customization (like
                color choices) included in the listing, it is clear in your description that the
                photos shown are just examples.
              </li>
            </ol>
          </Paragraph>
          <Paragraph>
            <span>b. What Can't be Sold on illume</span>
            <br />
            Even if they otherwise meet our marketplace criteria, prohibited items such as:
            <ol>
              <li>Animal Products and Human Remains</li>
              <li>Alcohol, Tobacco, Drugs, Drug Paraphernalia, and Medical Drugs</li>
              <li>Dangerous Items: Hazardous Materials, Recalled Items, and Weapons</li>
              <li>Hate Items: Items that Promote, Support, or Glorify Hatred</li>
              <li>Illegal Items, Items Promoting Illegal Activity, and Highly Regulated Items</li>
              <li>Internationally Regulated Items</li>
              <li>Pornography and Mature Content</li>
              <li>Violent Items: Items that Promote, Support, or Glorify Violence</li>
              <li>Reiki and distance healing</li>
              <li>Spellcasting</li>
              <li>Prayers</li>
              <li>Rituals</li>
              <li>
                Items with a metaphysical outcome (for example: attracting wealth, love, gambling
                luck, more business, employment opportunities, or assistance with legal or
                relationship situations)
              </li>
            </ol>
            <br />
            <span>
              All listings must offer an item for sale (which includes digitally delivered items,
              and can also include reserved listings). You may not create a listing for the purpose
              of sharing a referral code, posting a want ad, or similar activity that does not offer
              a physical or digital item for sale.
            </span>
            <br />
            <span>
              Reselling is not allowed on illume. Reselling refers to listing an item as handmade
              when you were not involved in designing or making that item.
            </span>
            <br />
            <span>
              Keep in mind that members may flag listings that appear to violate our policies for
              illume's review. illume may remove any listings that violate our policies. illume may
              also suspend or terminate your account for any violations.
            </span>
            <br />
            <span>
              If you are raising money on behalf of a charity, you must obtain that charity’s
              consent. Please reach out to{' '}
              <Link href='mailto: email@tryillume.com'>email@tryillume.com</Link> for more
              information about donating any portion of your sales to charity.
            </span>
          </Paragraph>
          <Paragraph>
            <span>c. Managing your illume Shop</span> <br />
            <span>
              Your shop represents you and your business to the illume community. It’s important
              that you, your items and your shop are honestly and accurately represented.
            </span>
          </Paragraph>
          <Paragraph>
            By selling on illume, you agree that you will:
            <ol>
              <li>Provide honest, accurate information in your About section.</li>
              <li>Honor your Shop Policies.</li>
              <li>
                Ensure your shop content, such as any text, photos or videos used to represent
                yourself, your shop or your listings, abide by illume’s policies.
              </li>
              <li>Accurately represent your items in listings and listing photos.</li>
              <li>
                Respect the intellectual property of others. If you feel someone has violated your
                intellectual property rights, you can report it to illume at{' '}
                <Link href='mailto: email@tryillume.com'>email@tryillume.com</Link>
              </li>
              <li>
                Not create duplicate shops or take any other action (such as manipulating clicks,
                carts or sales) for the purpose of shilling, manipulating search or circumventing
                illume's policies.
              </li>
              <li>Not coordinate pricing with other sellers.</li>
            </ol>
          </Paragraph>
          <Paragraph>
            <span>d. Seller Standards</span> <br />
            <span>
              By listing a product for sale on illume you understand and agree that you are
              responsible for complying with all applicable laws and regulations for the products
              you list for sale, including any required labels and warnings. illume assumes no
              responsibility for the accuracy, labeling, or content of your listings.
            </span>
          </Paragraph>
          <Paragraph>
            <span>Meeting Service Level Standards</span> <br />
            <span>
              As a seller, you must provide great customer service and maintain trust with your
              customers. illume may reach out to you if your shop fails to meet the list below.
            </span>
          </Paragraph>
          <Paragraph>
            By selling on illume, you agree to:
            <ol>
              <li>
                Honor your shipping, sales tax, and processing times. Sellers are obligated to ship an item or
                otherwise complete a transaction with a customer in a prompt manner, unless there is
                an exceptional circumstance. Please be aware that legal requirements for shipping times vary by state.
              </li>
              <li>Honor the commitments you make in your <Link href="https://intercom.help/illume/en/articles/4820689-merchant-questions-policies">merchant policies and FAQ.</Link></li>
              <li>
                Resolve disagreements or disputes directly with the customer. In the unlikely event
                that you can’t reach a resolution, illume can help through our case system via{' '}
                <Link href='mailto: email@tryillume.com'>email@tryillume.com</Link>.
              </li>
              <li>
                If you are unable to complete an order, you must notify the customer and cancel the
                order.
              </li>
            </ol>
          </Paragraph>
          <HeadingList id='illume-member'>2. Being a Member of the illume Community</HeadingList>
          <Paragraph>
            At illume, everyone is expected to treat fellow members of the illume community with
            respect and kindness. As a seller, you have additional responsibilities to safeguard
            personal information and communicate promptly with customers in order to provide a great
            customer experience.
          </Paragraph>
          <Paragraph>
            <span>a. Creating and Uploading Content</span> <br />
            <span>
              As a member of illume, you have the opportunity to create and upload a variety of
              content like listings, Messages, text, photos, and videos. In order to keep our
              community safe and respectful, you agree that you will not upload content that is:
            </span>
            <ol>
              <li>Abusive, threatening, defamatory, harassing, discriminating, or hateful;</li>
              <li>Graphic, obscene, or vulgar;</li>
              <li>In violation of someone else’s privacy or intellectual property rights; or</li>
              <li>False, deceptive, or misleading.</li>
            </ol>
          </Paragraph>
          <Paragraph>
            <span>b. Privacy and Protecting Personal Information</span> <br />
            <span>
              You are responsible for protecting members’ personal information you receive or
              process, and you must comply with all relevant legal requirements. This includes
              applicable data protection and privacy laws that govern the ways in which you can use
              illume user information. These laws may require that you post and comply with your own
              privacy policy, which must be accessible to illume users with whom you interact. Your
              privacy policy must be compatible with this policy and illume’s Terms of Use.
            </span>
            <br />
            <span>
              In particular, when you sell using our Services (subject to this Policy) or use
              illume’s API, you may receive and determine what to do with certain personal
              information, such as when communicating with users and entering into transactions with
              customers. This means you process personal information (for example, customer name,
              email address, and shipping address) and, to the extent you do so, under EU law, you
              are an independent controller of data relating to other users that you may have
              obtained through the Services.For more information on the General Data Protection
              Regulation, see more resources at https://gdpr-info.eu and http://gdprandyou.ie. As a
              data controller (that is someone who decides what personal data is collected and the
              purpose you’ll use the data for) to the extent that you process user personal
              information outside of the Services, you may be required under applicable data
              protection and privacy laws to honor requests received from such users for data
              access, portability, correction, deletion, and objections to processing. Also, if you
              disclose personal information without the customer’s proper consent, you are
              responsible for that unauthorized disclosure. This includes, for example, disclosures
              you make or unintentional data breaches. For example, you may receive a customer’s
              email address or other information as a result of entering into a transaction with
              that customer. This information may only be used for illume-related communications or
              for illume-facilitated transactions. You may not use this information for unsolicited
              commercial messages or unauthorized transactions. Without the customer’s consent, and
              subject to other applicable illume policies and laws, you may not add any illume
              member to your email or physical mailing list, use that customer’s identity for
              marketing, or obtain or retain any payment information. Please bear in mind that
              you're responsible for knowing the standard of consent required in any given instance.
              If illume and you are found to be joint data controllers of personal information, and
              if illume is sued, fined, or otherwise incurs expenses because of something that you
              did in your capacity as a joint data controller of customer personal information, you
              agree to indemnify illume for the expenses it occurs in connection with your
              processing of customer personal information.
            </span>
            <br />
            <span>
              For more information, please see our <Link href={PRIVACY_LINK}>Privacy Policy</Link>.
            </span>
          </Paragraph>
          <Paragraph>c. Communication Standards</Paragraph>
          <Paragraph>
            <span>i. Communicating Cancellations</span> <br />
            <span>
              If you are unable to complete a transaction, you must notify the customer via illume
              Messages and cancel the transaction. If the customer already submitted payment, you
              must issue a full refund. You are encouraged to keep proof of any refunds in the event
              a dispute arises. All cancellations are subject to our Cancellation Policy.
            </span>
            <br />
            <span>
              European Union Right of Withdrawal customers in the European Union (EU) may be
              entitled to a 14-day “right of withdrawal” after receiving an item, during which they
              may return an item for any reason. The details of this right vary by EU member state.
              The right of withdrawal may not apply to certain items like custom items or perishable
              goods. Rights with respect to digital items vary by EU member state.
            </span>
            <br />
            <span>
              Please be aware that in addition to this policy, each country has its own laws
              surrounding shipping, cancellations, returns, and exchanges. Please familiarize
              yourself with the laws of your own country and those of your customers’ countries.
            </span>
          </Paragraph>
          <HeadingList id='feedback'>3. Feedback, Disputes and Your Success</HeadingList>
          <Paragraph>
            <span>a. illume’s Case System</span> <br />
            <span>
              The Case system is how a customer notifies illume of an order issue or dispute, in
              order for illume to help them reach a resolution. Customers must contact sellers
              directly via the Help with Order link and attempt to resolve any outstanding issues
              before opening a case on illume. For this reason, it is important that you fill out
              your shop policies and regularly respond to Messages from your customers. Once a
              customer contacts you to notify you of a problem with an order, you will have 48 hours
              to resolve the issue. If the issue is unresolved in this time frame, a case may be
              opened by the customer. Once a case has been opened, illume will assist in the
              resolution of the case between the customer and seller. This may include, but is not
              limited to, automatically closing the case and issuing a refund to the customer, or
              reviewing the case further to help the customer and seller resolve it as quickly as
              possible.
            </span>
            <br />
            <span>
              illume reserves the right to resolve an order issue before the 48-hour window for
              circumstances including, but not limited to, seller inactivity, harassment, refusal of
              service, manipulation, and undermining the integrity of the case system.
            </span>
            <br />
            <span>
              Customers may file a case for a non-delivery or a not-as-described item illume may
              request your assistance in resolving a case opened against your shop. By using
              illume’s case system, you understand that illume may use your personal information for
              the purpose of resolving disputes with other members. For more information on our
              privacy policy, <Link href={PRIVACY_LINK}>click here</Link>.
            </span>
            <br />
            <span>
              If illume determines that an item is not as described, you will be required to refund
              the order, including original shipping and return shipping. illume reserves the right
              to issue a refund to the customer and recoup funds from your account, including your
              payment account reserve.
            </span>
          </Paragraph>
          <Paragraph>
            <span>c. Your Seller Account and illume’s Terms</span> <br />
            <span>
              In order to keep illume safe and improve our Services, we may take actions that limit
              the visibility of your shop or that impact your payment account. In the event a shop
              sees unusual order activity, or we otherwise believe that your actions or shop may
              result in customer disputes, chargebacks, increased risk of fraud, counterfeiting, or
              other claims, illume may take actions such as limiting visibility of your account, or
              placing restrictions or reserves on your payments account, in accordance with our
              Terms of Service, including this Policy. When appropriate and permitted by law, illume
              will communicate information to the affected seller about the issue.
            </span>
            <br />
            <span>
              In the event a shop sees an unusual spike in orders, particularly in a high demand
              category, a shop may see an increase or decrease in its search ranking. Often, if a
              shop sees an increase in fulfilled orders and good reviews, this may result in higher
              visibility and search rank. However, sometimes a rapid increase in orders can reduce
              visibility.
            </span>
            <br />
            <span>
              In addition, we may limit the visibility of listings in the interest of keeping illume
              safe, and improving our Services. For example, listings may have decreased visibility
              because they include terms that represent a prohibited item or based on third party
              policies. These listings may also be restricted from appearing in one or more features
              of the illume Services. While these listings or ads may have limited visibility, they
              are still discoverable in search.
            </span>
            <br />
            <span>
              If illume has reason to believe you, Your Content, or your use of the Services violate
              our Terms, including this Seller Policy, we may deactivate Your Content or suspend or
              terminate your account (and any accounts illume determines is related to your account)
              and your access to the Services. Generally, illume will notify you that Your Content
              or account has been suspended or terminated, unless you’ve repeatedly violated our
              Terms or we have legal or regulatory reasons preventing us from notifying you.
            </span>
          </Paragraph>
        </Container>
        <Footer />
      </Layout>
    </ThemeProvider>
  );
};

export default Terms;
